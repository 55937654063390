#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}


/* font-family: 'Abel', sans-serif;
font-family: 'Aldrich', sans-serif;
font-family: 'Bebas Neue', cursive;
font-family: 'DM Sans', sans-serif;
font-family: 'Jost', sans-serif;
font-family: 'Orbitron', sans-serif;
font-family: 'Play', sans-serif; */
