@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
    from {background-position: 0;}
    to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
}

.stars {
  background:#000 url('./Images/StarMap.png') repeat top center;
  z-index: -1;
}

.twinkling{
  background:transparent url('./Images/TwinklingMask.png') repeat top center;

  -moz-animation:move-twink-back 200s linear infinite;
  -ms-animation:move-twink-back 200s linear infinite;
  -o-animation:move-twink-back 200s linear infinite;
  -webkit-animation:move-twink-back 200s linear infinite;
  animation:move-twink-back 200s linear infinite;
  z-index: -1;
}

.clouds{
    background:transparent url(./Images/RedClouds.png) repeat top center;
    opacity: 0.95;
    background-size: 175%;

    -moz-animation:move-clouds-back 200s linear infinite;
    -ms-animation:move-clouds-back 200s linear infinite;
    -o-animation:move-clouds-back 200s linear infinite;
    -webkit-animation:move-clouds-back 200s linear infinite;
    animation:move-clouds-back 200s linear infinite;
    z-index: -1;
}

@media only screen and (max-width: 960px) {
    .clouds {
        background-size: 400%;
    }
}
